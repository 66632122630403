import { Fragment, useState } from "react";
import SubSystemList from "../../subSystem/subsystemList/SubsystemList";
import SubsystemSearch from "../../subSystem/subsystemSearch/SubsystemSearch";

const MapContentForLeftPanel = ({ close, showAnimation }) => {
  const [searchInput, setSearchInput] = useState("");
  const [filtersVars, setFiltersVars] = useState([]);

  const varsHandler = (variable) => {
    if (!filtersVars.includes(variable))
      setFiltersVars((prev) => prev.concat(variable));
    else setFiltersVars((prev) => prev.filter((item) => item !== variable));
  };

  const clearVarsHandler = () => {
    setFiltersVars([]);
  };

  return (
    <Fragment>
      <SubsystemSearch
        close={close}
        findTypeName={setSearchInput}
        setVars={varsHandler}
        filtersVars={filtersVars}
        clearVars={clearVarsHandler}
      />

      <SubSystemList
        showAnimation={showAnimation}
        filterTypeName={searchInput}
        filtersVars={filtersVars}
      />
    </Fragment>
  );
};

export default MapContentForLeftPanel;
