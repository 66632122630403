import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
  HeatmapLayer,
} from "@react-google-maps/api";
import { LoadingCircle } from "../../UI/spinners/LoadingSpinners";

import GoogleMapStyles from "./GoogleMapStyles";

import { useStore } from "../../../hooks/store";

// markers
import green_mark from "./../../../assets/icons/Map/green_mark_icon.svg";
import orange_mark from "./../../../assets/icons/Map/orange_mark_icon.svg";
import blue_mark from "./../../../assets/icons/Map/blue_mark_icon.svg";
import purple_mark from "./../../../assets/icons/Map/purple_mark_icon.svg";
import yellow_mark from "./../../../assets/icons/Map/yellow_mark_icon.svg";
import light_blue_mark from "./../../../assets/icons/Map/light_blue_mark_icon.svg";
import pin_blue from "./../../../assets/icons/Map/pin_blue_icon.svg";
import customMarker from "./../../../assets/icons/Map/marker_icon_32x32.png";
import Spiderfy from "./Spiderfy";
import { cityCenter } from "../../../../SMARTCITY/hardCodeData/DATA";

const icons = {
  1: green_mark,
  2: orange_mark,
  3: blue_mark,
  4: purple_mark,
  5: yellow_mark,
  6: light_blue_mark,
  7: pin_blue,
  8: pin_blue,
  9: purple_mark,
  10: orange_mark,
};
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const libraries = ["visualization", "places"];

let c = 0;
const SmartIsCityMap = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQlCnn8F1D0S9ymnWZwc_WFx2ZS6g8w1Q",
    // ...otherOptions
    libraries: libraries,
  });
  const [map, setMap] = useState(null);
  const [heatMap, setHeatMap] = useState(false);
  const [clickedMarkerData, setClickedMarkerData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState(cityCenter);
  const goCenter = useRef(null);

  const state = useStore()[0];

  const markStyleRef = useRef(null);

  const zoomRef = useRef(15);

  const backupActiveTypeId = useRef(cityCenter);

  const listener = useRef(null);
  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const recenterMap = (position) => {
    if (position && center !== position) {
      goCenter.current = {
        lat: (center.lat - position.lat) / 5,
        lng: (center.lng - position.lng) / 5,
      };
      clearInterval(listener.current);
      listener.current = setInterval(smoothChangeCenter, 50);
      c = 5;

      if (zoomRef.current < 15) zoomRef.current = 15;
    }
  };

  const smoothChangeCenter = () => {
    console.log(c);
    if (c === 1) {
      clearInterval(listener.current);
      listener.current = null;
    }
    c -= 1;
    setCenter((prev) => ({
      lat: prev.lat - goCenter.current.lat,
      lng: prev.lng - goCenter.current.lng,
    }));
  };

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = new URLSearchParams(search).get("sensorId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      let data = [];
      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        backupActiveTypeId.current = tempTypeIdActive[0].id;
        markStyleRef.current = icons[tempTypeIdActive[0].id];
        if (currTypeURL !== "9") setHeatMap(false);

        data = state.sensors.filter(
          (item) => item.typeId === tempTypeIdActive[0].id
        );
        if (currSensorURL) {
          const tempMarker = data.filter(
            (item) => item.id.toString() === currSensorURL
          );

          if (tempMarker.length > 0) {
            setClickedMarkerData(tempMarker[0].id);
            recenterMap(tempMarker[0].position);
          }
        } else {
          setClickedMarkerData(null);
          recenterMap(cityCenter);
        }
      }

      setMarkers(data);
    }
  }, [search, state.types, state.sensors]);

  const calculator = (inputMarkers, numStyles) => {
    let index = 0;
    let count = inputMarkers.length;
    let dv = count;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    console.log(index);
    if (backupActiveTypeId.current !== "9")
      return {
        text: inputMarkers.length,
        index: index,
        title: "",
      };

    let avg = 0;
    for (let i = 0; i < inputMarkers.length; i++) {
      const tempId = inputMarkers[i].title;

      for (let y = 0; y < markers.length; y++) {
        if (markers[y].id.toString() === tempId) {
          const sumMarkers = markers[y].metrics.filter(
            (item) => item.content === "Ποσοστό πληρότητας κάδου"
          );

          sumMarkers.map((item) => {
            const num = parseInt(item.value.replace("%", ""));
            avg += num;
          });
        }
      }
    }

    index = Math.min(index, numStyles);

    return {
      text: (avg / count).toFixed(0) + "%",
      index: index,
      title: "s",
    };
  };

  const MapMarker = React.forwardRef((props, ref) => {
    return <Marker position={props.position} title={props.title} ref={ref} />;
  });

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap
          center={center}
          zoom={zoomRef.current}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={() => setCenter(map.getCenter().toJSON())}
          onZoomChanged={() => map && (zoomRef.current = map.getZoom())}
          options={{
            styles: GoogleMapStyles,
          }}
        >
          {/* Child components, such as markers, info windows, etc. */}

          <Fragment>
            {heatMap && (
              <HeatmapLayer
                data={markers.map(
                  (marker) =>
                    new window.google.maps.LatLng(
                      marker.position.lat,
                      marker.position.lng
                    )
                )}
              />
            )}
            {!heatMap && backupActiveTypeId.current === "10" && (
              <Spiderfy>
                {markers.map((marker) => (
                  <Marker
                    title={marker.id.toString()}
                    key={marker.id}
                    position={marker.position}
                    animation={4}
                    clickable={true}
                    icon={
                      marker.id === clickedMarkerData
                        ? customMarker
                        : marker.bigMarker
                        ? marker.bigMarker
                        : markStyleRef.current
                    }
                  >
                    {clickedMarkerData && marker.id === clickedMarkerData && (
                      <InfoWindow position={marker.position}>
                        <p>{marker.title}</p>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </Spiderfy>
            )}
            {!heatMap && backupActiveTypeId.current !== "10" && (
              <MarkerClusterer
                options={options}
                averageCenter={true}
                calculator={calculator}
              >
                {(clusterer) =>
                  markers.map((marker) => (
                    <Marker
                      title={marker.id.toString()}
                      key={marker.id}
                      position={marker.position}
                      animation={4}
                      clickable={true}
                      // clusterer={
                      //   backupActiveTypeId.current === "9" ? false : clusterer
                      // }
                      clusterer={clusterer}
                      icon={
                        marker.id === clickedMarkerData
                          ? customMarker
                          : marker.bigMarker
                          ? marker.bigMarker
                          : markStyleRef.current
                      }
                      onClick={() => {
                        console.log(clickedMarkerData, marker.id);
                        if (clickedMarkerData !== marker.id) {
                          history.push(
                            `${pathname}?typeId=${backupActiveTypeId.current}&sensorId=${marker.id}`
                          );

                          props.showAnimation(true);
                        } else {
                          props.showAnimation(false);
                        }
                      }}
                    >
                      {clickedMarkerData && marker.id === clickedMarkerData && (
                        <InfoWindow position={marker.position}>
                          <p>{marker.title}</p>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))
                }
              </MarkerClusterer>
            )}
          </Fragment>

          <>
            {backupActiveTypeId.current === "9" && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  transform: "translateX(calc(50vw - (100% / 2 )))",
                  textAlign: "center",
                  padding: "5px 10px",
                  backgroundColor: "green",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeatMap((prev) => !prev)}
                >
                  <span>{heatMap ? "HeatMap: OFF" : "HeatMap: ON"}</span>
                </p>
              </div>
            )}
          </>
        </GoogleMap>
      )}
      {!isLoaded && <LoadingCircle />}
    </Fragment>
  );
};

export default React.memo(SmartIsCityMap);
