import classes from "./MapContainer.module.css";
import React, { useState, Fragment, useEffect } from "react";

import LeftPanel from "../../../layout/leftPanel/LeftPanel";
import MapContentForLeftPanel from "../leftPanelContent/MapContentForLeftPanel";
import RightPanel from "../../../layout/rightPanel/RightPanel";
import RightPanelContent from "../rightPanelContent/RightPanelContent";
import { useLocation } from "react-router-dom";
import FastChart from "../../charts/FastChart/FastChart";

let listener = null;
const MapContainer = (props) => {
  const [showInfoPanel, setShowInfoPanel] = useState(false);

  const { search } = useLocation();

  const close = () => {
    setShowInfoPanel(false);
  };

  const showAnimation = (value) => {
    if (showInfoPanel && value) {
      clearTimeout(listener);
      setShowInfoPanel(false);
      listener = setTimeout(() => setShowInfoPanel(true), 250);
    } else {
      setShowInfoPanel(true);
    }
  };

  useEffect(() => {
    return () => clearTimeout(listener);
  }, []);

  console.log(props);

  return (
    <Fragment>
      <LeftPanel open>
        <MapContentForLeftPanel showAnimation={showAnimation} close={close} />
      </LeftPanel>

      {(new URLSearchParams(search).get("typeId") === "9" ||
        new URLSearchParams(search).get("typeId") === "1") && (
        <LeftPanel className={classes.statistics_btn} open={false}>
          <FastChart typeId={new URLSearchParams(search).get("typeId")} />
        </LeftPanel>
      )}

      <RightPanel show={showInfoPanel} close={close}>
        <RightPanelContent />
      </RightPanel>
      <div className={props.mapFrame ? classes.wrapper_full : classes.wrapper}>
        {React.cloneElement(props.children, {
          showAnimation,
        })}
      </div>
    </Fragment>
  );
};

export default MapContainer;
