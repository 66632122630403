import { useEffect, useContext } from "react";
import { useStore } from "../../TEMPLATE/hooks/store";
import { getAllSensors, getAllTypes } from "../../TEMPLATE/services/feed";
import {
  feedBins,
  feedEnvironment_Meteo,
  feedFleeto,
  feedHydro,
  feedMeshlium,
  feedParking,
  feedWifi,
} from "../../TEMPLATE/services/metrics";
import {
  allTypesUrl,
  allSensorsUrl,
  meteoURL,
  fleetoUrl,
  binsUrl,
  wifiUrl,
  enviURL,
  hydroUrl,
  parkingUrl,
} from "../hardCodeData/DATA";
import LanguageContext from "./../../TEMPLATE/hooks/language-context";

const DynamicData = () => {
  const dispatch = useStore(false)[1];

  const controller = new AbortController();
  const { signal } = controller;

  const languageCtx = useContext(LanguageContext);

  useEffect(() => {
    const feed_types_call = async () => {
      const data = await getAllTypes(
        `${allTypesUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_TYPES", data);
    };
    const feed_sensors = async () => {
      const data = await getAllSensors(
        `${allSensorsUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_SENSORS", data);
      getMetrics().catch((error) => console.warn(error));
    };

    feed_types_call().catch((error) => console.warn(error));
    feed_sensors().catch((error) => console.warn(error));
    return () => controller.abort();
  }, [languageCtx.languageCode]);

  const getMetrics = async () => {
    feedEnvironment_Meteo(`${enviURL}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_METRICS", data))
      .catch((error) => console.warn(error));

    // feedMeshlium(`${meshURL}&lang=${languageCtx.languageCode}`, signal)
    //   .then((data) => dispatch("ADD_METRICS", data))
    //   .catch((error) => console.warn(error));

    feedEnvironment_Meteo(
      `${meteoURL}&lang=${languageCtx.languageCode}`,
      signal
    )
      .then((data) => dispatch("ADD_METRICS", data))
      .catch((error) => console.warn(error));

    // feedPOIS(`${poiURL}&lang=${languageCtx.languageCode}`, signal)
    //   .then((data) => dispatch("ADD_SENSORS", data))
    //   .catch((error) => console.warn(error));

    feedFleeto(`${fleetoUrl}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));

    feedBins(`${binsUrl}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));

    feedHydro(`${hydroUrl}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));

    // feedWifi(`${wifiUrl}&lang=${languageCtx.languageCode}`, signal)
    //   .then((data) => dispatch("ADD_METRICS", data))
    //   .catch((error) => console.warn(error));

    feedParking(`${parkingUrl}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));
  };

  return null;
};

export default DynamicData;
