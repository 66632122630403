import classes from "./BoxTitle.module.css";

import Tooltip from "../../../layout/tooltip/Tooltip";
import { useStore } from "../../../hooks/store";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const defaultIcon = (
  <i className="im im-icon-Truck" style={{ color: "white", fontSize: "30px" }} />
);

const BoxTitle = ({
  className,
  styleIcon,
  icon,
  link,
  id,
  title,
  externalLink,
  infoContext,
  element,
}) => {
  const dispatch = useStore(false)[1];
  const { t } = useTranslation();
  return (
    <div className={[classes.flexContainer, className].join(" ")}>
      {icon && (
        <i
          className={icon}
          style={
            styleIcon
              ? { color: "white", fontSize: "30px", ...styleIcon }
              : { color: "white", fontSize: "30px" }
          }
        />
      )}
      {/* {!icon && <i
          className='im im-icon-Truck'
          style={
            styleIcon
              ? { color: "white", fontSize: "30px", ...styleIcon }
              : { color: "white", fontSize: "30px" }
          }
        />} */}
      {!icon && defaultIcon}

      <div className={classes.title}>
        {link && (
          <Link onClick={() => id && dispatch("CLICKED_TYPE", id)} to={link}>
            {title}
          </Link>
        )}
        {externalLink && (
          <a href={externalLink} target="_blank" rel="noreferrer">
            {title}
          </a>
        )}

        {!link && !externalLink && title}

        {infoContext && (
          <span className={classes.triggerTooltip}>
            <i
              className="fa fa-info-circle"
              style={{
                color: "white",
                margin: "2px 5px 0",
                position: "absolute",
              }}
            />
            <Tooltip tooltipContent={infoContext} />
          </span>
        )}
      </div>
      <div
        className={[
          classes.lastElementPlaceholder,
          classes.triggerTooltip,
        ].join(" ")}
      >
        {element}
        <Tooltip
          tooltipContent={t("Σύρετε από εδώ")}
          className={classes.dragAndDropTooltip}
        />
      </div>
    </div>
  );
};

export default BoxTitle;
