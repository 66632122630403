import classes from "./Footer.module.css";
import appleMini from "./../../assets/images/apple_mini.png";
import androidMini from "./../../assets/images/android_mini.jpg";
import information from "./../../assets/images/information_project.png";
import {
  androidAppLink,
  informationLink,
  informationLinkEN,
  iosAppLink,
} from "../../../SMARTCITY/hardCodeData/DATA";
import { useContext } from "react";
import LanguageContext from "../../hooks/language-context";

const Footer = () => {
  const languageCtx = useContext(LanguageContext);
  return (
    <div className={classes.footer}>
      <p className={classes.copyright}>&copy;{(new Date().getFullYear())} DotSoft</p>
      <div>
        <img
          src={information}
          alt={"information-application"}
          onClick={() =>
            window.open(
              languageCtx.languageCode === "en"
                ? informationLinkEN
                : informationLink
            )
          }
        />
        <img
          src={appleMini}
          alt={"apple-application"}
          onClick={() => window.open(iosAppLink)}
        />
        <img
          src={androidMini}
          alt={"android-application"}
          onClick={() => window.open(androidAppLink)}
        />
      </div>
    </div>
  );
};

export default Footer;
