import classes from "./BoxesContainer.module.css";
import { useContext, useEffect, useState } from "react";
import { MuuriComponent } from "muuri-react";
import "./BoxesContainer.css";
import { useTranslation } from "react-i18next";
import Box from "../../../TEMPLATE/components/box/Box";
import { UvUrl } from "../../hardCodeData/DATA";
import Meteor from "../../../TEMPLATE/components/categories/meteo/Meteor";
import Meshlium from "../../../TEMPLATE/components/categories/meshlium/Meshlium";
import UvIndexes from "../../../TEMPLATE/components/categories/uv/UvIndexes";
import Weather from "../../../TEMPLATE/components/categories/weather/Weather";
import CustomCategoryUniversity from "../categories/CustomCategoryUniversity";
import LanguageContext from "../../../TEMPLATE/hooks/language-context";
import MapPOIS from "../../../TEMPLATE/components/categories/mapPOIS/MapPOIS";
import CustomCategoryDiav from "../categories/CustomCategoryDiav";
import Applicants from "../categories/Applicants";
import IanosEnergy from "../categories/IanosEnergy";
import Parking from "../categories/Parking";
import MeteorPatra from "../../../TEMPLATE/components/categories/meteo/MeteorPatra";

const maxEntitiesLargeBox = 9;
const maxEntitiesMediumBox = 2;

const options = {
  dragSortHeuristics: {
    sortInterval: 0,
  },
  layoutDuration: 400,
  dragRelease: {
    duration: 400,
    easing: "ease-out",
  },
  dragEnabled: true,
  dragStartPredicate: function (item, hammerEvent) {
    if (hammerEvent.target.matches("#draggable")) {
      return true;
    }
    return false;
    // distance: 0,
    // delay: 500,
  },
  dragContainer: document.body,
  // The placeholder of an item that is being dragged.
  dragPlaceholder: {
    enabled: true,
    createElement: function (item) {
      // The element will have the Css class ".muuri-item-placeholder".
      return item.getElement().cloneNode(true);
    },
  },
};
const BoxesContainer = () => {
  const [uvData, setUvData] = useState([]);
  const languageCtx = useContext(LanguageContext);
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation();

  useEffect(() => {
    const getUVMetrics = async () => {
      const req = await fetch(`${UvUrl}&lang=${languageCtx.languageCode}`, {
        signal,
      }).catch((error) => console.warn(error));

      if (req === undefined || !req.ok) return;

      const data = await req.json();

      setUvData(data);
      console.log(data);
    };

    getUVMetrics().catch((error) => console.warn(error));
  }, [languageCtx.languageCode]);

  return (
    <div className={classes.boxesContainer}>
      <MuuriComponent {...options}>

        {/* <Box className={classes.large} typeId="1">
         <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="1"
            title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={true}
          />
        </Box> */}

        <Box className={classes.large} typeId="5">
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="5"
            title={t("ΜΕΤΕΩΡΟΛΟΓΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={false}
          />
        </Box>

        <Box className={[classes.map_box, classes.bgColor].join(" ")} typeId="8">
         <MapPOIS typeId="8" title="ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ" />
        </Box>



        <Box className={classes.map_box} typeId="31">
          <MapPOIS typeId="31" title="ΠΙΝΑΚΙΔΕΣ ΠΛΗΡΟΦΟΡΗΣΗΣ" />
        </Box>

        <Box className={classes.map_box} typeId="29">
          <MapPOIS typeId="29" title="ΗΛΕΚΤΡΟΦΟΡΤΙΣΤΕΣ" />
        </Box>

        {/* <Box
          className={[classes.medium, classes.weatherBox].join(" ")}
          title="ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ"
        >
          <Weather />
        </Box>

        <Box className={classes.medium} typeId="1">
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="1"
            title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={true}
            group={"3"}
          />
        </Box> */}

        {/* <Box className={classes.map_box} typeId="4">
         <MapPOIS typeId="4" title="WIFI HOTSPOTS" />
        </Box> */}

        {/* <Box className={[classes.large, classes.bgGreenColor].join(" ")} typeId="1">
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="1"
            title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={false}
            group={"1"}
          />
        </Box> */}

        {/* <Box className={classes.large} typeId="5">
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="5"
            title={t("ΜΕΤΕΩΡΟΛΟΓΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={false}
          />
        </Box> */}

        {/* <Box className={classes.large} typeId="1">
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="1"
            title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={true}
            group={"1"}
          />
        </Box> */}
        {/* <Box className={classes.large} typeId="5" >
          <Meteor
            maxEntities={maxEntitiesLargeBox}
            typeId="5"
            title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΑ ΔΕΔΟΜΕΝΑ")}
            showSubTitle={false}
          />
        </Box> */}

        <Box className={classes.map_box} typeId="9">
          <MapPOIS typeId="9" title="ΚΑΔΟΙ" />
        </Box>

        {/* <Box className={classes.map_box} typeId="8" title="ΚΙΝΗΤΙΚΟΤΗΤΑ">
         <MapPOIS typeId="8" title="ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ" />
        </Box> */}
        {/* <Box className={classes.map_box} typeId="8">
         <MapPOIS typeId="8" title="ΔΙΑΧ/ΣΗ ΣΤΟΛΟΥ" />
        </Box> */}


        {/* <Box className={`${classes.large} ${classes.ianosEnergy}`} typeId="12">
         <IanosEnergy />
        </Box> */}

        {/* <Box className={classes.map_box} typeId="4">
          <MapPOIS typeId="4" title="WIFI HOTSPOTS" />
        </Box> */}


        {/* <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UvIndexes data={uvData[0]} typeId="5" />
        </Box>

        <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UvIndexes data={uvData[1]} typeId="5" />
        </Box>

        <Box
          className={[classes.medium, classes.uv_style].join(" ")}
          typeId="5"
        >
          <UvIndexes data={uvData[2]} typeId="5" />
        </Box> */}

        {/* <Box className={[classes.medium, classes.weatherBox].join(" ")}>
          <Weather />
        </Box> */}

        {/* Diavouleuseis */}
        {/*<Box*/}
        {/*  className={[classes.medium, classes.diav].join(" ")}*/}
        {/*  title="ΕΞΥΠΗΡΕΤΗΣΗ"*/}
        {/*>*/}
        {/*  <CustomCategoryDiav />*/}
        {/*</Box>*/}

        {/* Applicants */}
        {/*<Box*/}
        {/*  className={[classes.medium, classes.applicants].join(" ")}*/}
        {/*  title="ΕΞΥΠΗΡΕΤΗΣΗ"*/}
        {/*>*/}
        {/*  <Applicants />*/}
        {/*</Box>*/}

        <Box
          className={`${classes.medium} ${classes.parking}`}
          title={"Παρκινγ"}
        >
          <Parking id={0} typeId={"24"} />
        </Box>
      </MuuriComponent>
    </div>
  );
};

export default BoxesContainer;
