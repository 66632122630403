import { createContext, useState } from "react";
import cookies from "js-cookie";

const LanguageContext = createContext({
  authToken: "",
  languageCodeHandler: () => {},
});

const retrieveStoredLanguageCode = () => "el";

export const LanguageContextProvider = (props) => {
  const lngCode = retrieveStoredLanguageCode();

  let initialLanguageCode;
  if (lngCode) initialLanguageCode = lngCode;

  const [getLanguageCode, setLanguageCode] = useState(initialLanguageCode);

  const languageCodeHandler = (lngCode) => setLanguageCode(lngCode);

  const context = {
    languageCode: getLanguageCode,
    languageCodeHandler: languageCodeHandler,
  };

  return (
    <LanguageContext.Provider value={context}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
