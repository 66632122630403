import classes from "./Parking.module.css";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import { Fragment, useEffect, useState } from "react";
import BoxEntity from "../../../TEMPLATE/components/box/entity/BoxEntity";
import { baseUrl } from "../../hardCodeData/DATA";
import { Doughnut } from "react-chartjs-2";
import BoxSubTitle from "../../../TEMPLATE/components/box/subTitle/BoxSubTitle";
import img from "./../../assets/parking_icon.png";

const initData = {
  labels: ["Ελεύθερες", "Κατειλημμένες"],
  datasets: [
    {
      label: "My First Dataset",
      data: [50, 300],
      backgroundColor: ["#4FE676", "#FC1F19"],
      hoverOffset: 4,
    },
  ],
};

const config = {
  type: "doughnut",
  options: {
    borderWidth: 0,
    responsive: false,
    animation: {
      duration: 2000,
      delay: 500,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  },
};

const parking = baseUrl + "/api/api.php?func=parking";
const Parking = ({ id, typeId }) => {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState({ ...initData });
  const controller = new AbortController();
  const { signal } = controller;

  const getData = async () => {
    const RESPONSE = await fetch(parking, { signal });
    if (!RESPONSE || !RESPONSE.ok) return;
    const DATA = await RESPONSE.json();

    setChartData((prev) => ({
      ...prev,
      datasets: [
        {
          ...prev?.datasets[0],
          label: DATA?.[id].ParkingName,
          data: [
            DATA?.[id].Places - DATA?.[id].PlacesOccupied,
            DATA?.[id].PlacesOccupied,
          ],
        },
      ],
    }));

    setData(DATA);
  };

  useEffect(() => {
    getData().catch((error) => console.warn(error));

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Fragment>
      <BoxTitle
        // icon={"im im-icon-Business-ManWoman"}
        title={"ΘΕΣΕΙΣ ΣΤΑΘΜΕΥΣΗΣ"}
        infoContext={"Ελεύθερες θέσεις για το PARKING"}
        img={img}
        link={`/map?typeId=${typeId}`}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      <h1
        style={{
          textAlign: "center",
          marginBottom: 10,
          fontSize: 16,
          lineHeight: 1,
          fontWeight: 400,
          color: "white",
        }}
      >
        {data?.[id].ParkingName}
      </h1>
      <div className={classes.chartPlaceHolder}>
        <Doughnut data={chartData} {...config} />
        <div className={classes.chartLegend}>
          <h1>{data?.[id].Places - data?.[id].PlacesOccupied}</h1>
          <p>ελεύθερες</p>
        </div>
      </div>

      {/*{data &&*/}
      {/*  data.map((item, index) => (*/}
      {/*    <BoxEntity*/}
      {/*      key={index}*/}
      {/*      icon={null}*/}
      {/*      content={`${item.ParkingName}`}*/}
      {/*      value={`${item.Places - item.PlacesOccupied} Ε.Θ.`}*/}
      {/*    />*/}
      {/*  ))}*/}
    </Fragment>
  );
};

export default Parking;
