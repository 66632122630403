import { Route, Switch, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import { LanguageContextProvider } from "./hooks/language-context";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";

import configureTypesStore from "./hooks/types-store";
import configureSensorsStore from "./hooks/sensors-store";
import DynamicData from "../SMARTCITY/DynamicData/DynamicData";
import DynamicDataPatra from "../SMARTCITY/DynamicData/DynamicDataPatra";
import MapPage from "./pages/MapPage";
import Map from "./components/Map/Map";
import Charts from "./components/charts/Charts";

i18next
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "el"],
    fallbackLng: "el",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
      cookieMinutes: 7 * 24 * 60 * 60 * 1000, //7 days
    },
    react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  })
  .catch((error) => console.warn(error));

configureTypesStore();
configureSensorsStore();

const App = () => {
  const history = useLocation();
  return (
    <LanguageContextProvider>
      <DynamicData />
      {history.pathname !==
        "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" &&
        history.pathname !==
          "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet" && (
          <Header />
        )}
      <main
        style={
          history.pathname ===
            "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" ||
          history.pathname ===
            "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet"
            ? {
                marginTop: 0,
                marginBottom: 0,
                height: "100%",
              }
            : {}
        }
      >
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/map" component={MapPage} exact />

          <Route
            path="/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451"
            exact
          >
            <Map mapFrame={true} />
          </Route>

          <Route
            path="/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet"
            component={Charts}
            exact
          />
        </Switch>
      </main>
      {history.pathname !==
        "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" &&
        history.pathname !==
          "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet" && (
          <Footer />
        )}
    </LanguageContextProvider>
  );
};

export default App;
